<template>
    <page-main
      title="Target Industry"
      nextText="Tell us about your target company"
      buttonTabIndex="3"
      :displayBeta="true"
      eventName="nav"
      @save="save"
      @nav="validateAndNavigate"
    >
      <template v-slot:aside>
        <div>
          <ul>
            <p>To build successful cover letters, we combine your professional experience and career aspirations. In this section, you’ll select a resume to use and provide information about your target industry. </p>
            <p></p>
            <li>You must select a resume to continue.</li>
            <p></p>
            <li>If you don’t have a target industry, you can leave this section blank, but we recommend providing any information you can.</li>
            <p></p>
            <li>This section is specific to your target industry; we will go over your target company on the next page.</li>
          </ul>
        </div>
      </template>
      <div class="py-2 px-4 elevation-2 page-error-bar" v-if="hasLocalError">
        <v-icon color="red">mdi-alert-outline</v-icon>
        <span class="red--text pl-3">{{ this.errorMessage }}</span>
      </div>
      <template v-slot:nav>
        <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
        <modal cardTitle="Example" ctaText="Need an example?">
          <ul class="font-italic">
            <li class="mb-4">
              <span class="font-weight-bold">Example #1:</span>
              <ul>
                <li>
                  <span class="font-weight-bold"
                    >Target Industry: </span
                  >
                  Tech
                </li>
                <li>
                  <span class="font-weight-bold"
                    >What are skills you have that are a good fit for this industry?: </span
                  >
                  Excel; Computer Science; Problem-Solving; Adaptability; Leadership; Open-Mindedness
                </li>
                <li>
                  <span class="font-weight-bold"
                    >Why do you want to work in this industry? What do you find interesting or attractive about it?: </span
                  >
                  I want to work for the Tech industry because it combines practical application with innovation.
                </li>
              </ul>
            </li>
          </ul>
        </modal>
        <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
            <span class="font-weight-bold">Tips</span>
            <ul>
                <li>
                  <span class="font-weight-bold"
                    >Tip #1:</span
                  >
                  If you don’t have a target industry, leave those fields blank.
                </li>
                <li>
                  <span class="font-weight-bold"
                    >Tip #2:</span
                  >
                  If you don’t know what industry to select or what skills the industry wants, look up job descriptions for roles you are interested in and use the information there to help you.
                </li>
                <li>
                  <span class="font-weight-bold"
                    >Tip #3:</span
                  >
                  Feel free to use first-person pronouns in this section (e.g., I; we; us)
                </li>
              </ul>
        </modal>
        <v-btn
          outlined
          class="mt-4"
          block
          @click="navToContactUs"
        >
          Any feedback? Contact us!
        </v-btn>
      </template>
      <v-container fluid>
        <v-overlay color="primary" :absolute="true" v-if="loading">
          <v-progress-circular
            indeterminate
            color="accent"
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col cols="12" class="r-col">
            <r-single-select-auto-complete  
              label="What saved resume would you like to use?"
              :items="resumeNames"
              tabindex="6"
              v-model="selectedResume"
              :rules="[(v) => !!v.name || 'Required']"
            ></r-single-select-auto-complete>
          </v-col>
          <v-col cols="12" class="r-col">
            <r-single-select
              label="What industry are you targeting with this cover letter? (Select from dropdown or type in your own)"
              :items="baseTargetIndustries"
              v-model="targetedIndustry"
            ></r-single-select>
          </v-col>
          <v-col cols="12" class="r-col">
            <r-multi-select
              tabindex="1"
              label="What are skills you have that are a good fit for this industry?"
              :optionsList="baseAdjectives"
              :selected.sync="selectedSkills"
            ></r-multi-select>
          </v-col>
          <v-col cols="12" class="r-col">
            <r-text-field
              label="Why do you want to work in this industry? What do you find interesting or attractive about it? (1 to 3 sentences)"
              v-model="industryInterest"
            ></r-text-field>      
          </v-col>
        </v-row>
      </v-container>
      <r-dialog v-model="premiumBanner" persistent>
        <v-card-text class="text--primary text-body-1 pt-4 pb-3">
          <h4 class="pt-2 text-center">Oops! Looks like you will need Resumation Plus to use Cover Letter Creator feature!
          </h4>
          <p class="mt-2">Upgrade to get unlimited use of the Optimize with AI features, hide/show parts of your resume
            in-tool (customizations), save and store multiple resumes, and much more for only $5 a month!</p>
          <h4 class="pt-2 text-center">Would you like to upgrade to Resumation Plus?</h4>
        </v-card-text>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap justify-center' : 'flex-wrap justify-end'">
          <v-btn color="primary" outlined text @click="navToPremiumPage()"> Yes - let’s do it! </v-btn>
        </v-card-actions>
      </r-dialog>
      <r-dialog 
        v-if="!premiumBanner && createNewDialog"
        v-model="createNewDialog"
        persistent 
        title="New Cover Letter"
        transition="dialog-top-transition"
      >
        <div class="py-2 px-4 elevation-2 page-error-bar" v-if="hasLocalError">
          <v-icon color="red">mdi-alert-outline</v-icon>
          <span class="red--text pl-3">{{ this.errorMessage }}</span>
        </div>
        <v-overlay color="primary" :absolute="true" v-if="isAnalyzing">
          <v-progress-circular
            indeterminate
            color="accent"
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card-text class="text--primary text-body-1 pt-4 pb-3">
          <h4 class="pt-2 text-center">Please give your cover letter a title and feel free to add notes! You can also make changes to these fields later.</h4>
          <v-col cols="12">
            <v-textarea
                label="Give a name to this cover letter"
                v-model="coverLetterTitle"
                :rules="[rules.required]"
                rows="2"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
                label="Notes for this cover letter (Optional)"
                v-model="coverLetterNote"
                rows="2"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-text-field
              disabled
              label="Date Saved"
              :value="formattedDate"
              readonly
              rows="2"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between align-end pb-3">
          <v-btn 
          color="gray" 
          outlined text 
          @click="cancelNew()"
          > Cancel </v-btn>
          <v-btn 
          color="primary" 
          outlined text 
          :disabled="!coverLetterTitle || isAnalyzing" 
          @click="createNewMessage()"
          > Continue </v-btn>
        </v-card-actions>
      </r-dialog>
    </page-main>
  </template>
  
  <script>
  import { mapFields } from "vuex-map-fields";
  import { mapActions } from "vuex";
  import { required } from "../../utility/validation.js";
  import CoverLetterReportBuilder from "../../docxBuilder/coverLetterReportBuilder";
  import * as log from "../../utility/logging/logger.js";


  
  export default {
    name: "CoverLetterTargetIndustry",
    data() {
      return {
        premiumBanner: false,
        createNewDialog: false,
        coverLetterTitle: "",
        coverLetterNote: "",
        loading: false,
        isAnalyzing: false,
        resumeNames: [],
        errorMessage: "",
        hasLocalError: false,
        isCancel: false,


        rules: {
          required: (value) => required(value),
        },
      };
    },
    components: {
    },
    methods: {
      async save() {
        try {
          // Save is called after leaving page. Stops isSaving from getting stuck 
          if(!this.isCancel)
          {
            await this.updateCoverLetter();
          }
        } catch (e) {
          log.logError("Error saving cover letter");
        }
      },
      async buildImage()
      {
        try {
          const builder = new CoverLetterReportBuilder();
          builder.CreateText(this.aiOptimizedPhrasing);
          const blob = await builder.BuildDocx();      
          this.reportBase64 = await this.toBase64(blob);
          this.fileName = this.name + ".docx";
        } catch (e) {
          log.logError("Failed to build image", e);
        }
      },
      async toBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve(reader.result.slice(reader.result.indexOf("base64,", 0) + 7));
          reader.onerror = (error) => reject(error);
        });
      },
      buildFileName(extension) {
        return `${this.nameDisplay} Resume.${extension}`;
      },
      async validateAndNavigate() {
        this.loading = true;
        await this.save();
        if(this.isError)
        {
          this.hasLocalError = true;
          this.errorMessage = "Error saving cover letter information";
          this.loading = false;
        } else {
          if (!this.selectedResume.id) {
            return;
          }
          this.loading = false;
          this.nav('/CoverLetterTargetCompany');
        }
      },
      async loadData() {
        try {
          await this.refreshPremium();
          if (!this.isPremium ) 
          {
            this.dialog = false;
            this.premiumBanner = true;
          }

          await this.loadCoverLetterList();
          if (!this.coverLetterList.length > 0)
          {
            this.createNewDialog = true;
          }

          this.coverLetterTitle = this.name;

          if(this.coverLetterId === null)
          {
            this.createNewDialog = true;
            if (this.name == "")
            {
              this.selectedSkills = [...this.hardSkills, ...this.softSkills ,...this.selectedAdjectives, ...this.selectedRoles ];
            }
          }

          if(!this.isPageAllowed)
          {
            this.newCoverLetter();
            this.createNewDialog = true;
          }

          await this.loadResumeSubmissions();
          this.resumeNames = this.resumes.map(resume => ({
            name: resume.name,
            id: resume.id
          }));

          const matchedResume = this.resumes.find(resume => resume.id === this.selectedResumeId);
          if (matchedResume) {
            this.selectedResume.name = matchedResume.name;
          }
          if( this.selectedResume.id == null)
          {
            this.selectedResume.name = "";
          }
          this.loading = false;
        } catch (e) {
          this.errorMessage = "Error loading data";
          this.hasLocalError = true;
        }
      },
      nav(routerPath) {
        this.$router.push({ path: routerPath });
      },
      navToPremiumPage() {
        this.dialog = false;
        this.$router.push({ path: '/Upgrade' });
      },
      navToContactUs() {
        window.open('https://www.resumation.net/contact', '_blank');
      },
      async createNewMessage() {
        try {
          this.name = this.coverLetterTitle;
          this.note = this.coverLetterNote;
          this.isAnalyzing = true;
          await this.buildImage();
          await this.saveCoverLetter();
          if (this.isError) {
            this.hasLocalError = true;
            this.isAnalyzing = false;
            this.errorMessage = "Error saving new Cover Letter";
          } else {
            this.hasLocalError = false;
            this.isAnalyzing = false;
            this.loading = false;
            this.createNewDialog = false;
          }
        } catch (e) {
          this.hasLocalError = true;
          this.errorMessage = "Error saving new Cover Letter";
          this.isAnalyzing = false;
          this.loading = false;
        }
      },
      async cancelNew() {
        await this.save();
        this.isCancel = true;
        this.$router.back();
      },
      ...mapActions("activeCoverLetter", ["saveCoverLetter"]),
      ...mapActions("activeCoverLetter", ["updateCoverLetter"]),
      ...mapActions("activeCoverLetter", ["newCoverLetter"]),
      ...mapActions("coverLetter", ["loadCoverLetterList"]),
      ...mapActions("userSubmissions", ["loadResumeSubmissions"]),
      ...mapActions("auth", ["refreshPremium"]),
    },
    computed: {
      resumeHint() {
        return this.selectedResume ? '' : 'Required';
      },
      formattedDate() {
        const date = new Date();
        return date.toLocaleDateString();
      },
      ...mapFields("userSubmissions", {
        resumes: "resumes",
      }),
      ...mapFields("targetIndustry", ["baseTargetIndustries"]),
      ...mapFields("activeCoverLetter", [
        "coverLetterId",
        "aiOptimizedPhrasing",
        "isError",
        "selectedResume",
        "selectedResumeId",
        "targetedIndustry",
        "selectedSkills",
        "industryInterest",
        "reportBase64",
        "fileName",
        "isSaving",
        "name",
        "note",
        "isPageAllowed"
      ]),
      ...mapFields("coverLetter", [
        "coverLetterList"
      ]),
      ...mapFields("adjective", [
        "baseAdjectives",
        "selectedAdjectives",
      ]),
      ...mapFields("hardSkill", [
        "hardSkills",
        "hardSkillsDisplay",
        "hardSkillLabels",
        "baseHardSkills",
      ]),
      ...mapFields("softSkill", [
      "softSkills",
      "softSkillsDisplay",
      "baseSoftSkills",
      ]),
      ...mapFields("role", [
      "selectedRoles",
      ]),
      ...mapFields("auth", {
        isPremium: "isPremium",
        remainingAiOptimizerAttempts: "remainingAiOptimizerAttempts",
      }),
    },
    async mounted() {
      this.loading = true;
      await this.loadData();
    },
    beforeRouteLeave (to, from, next) {
      if(to.fullPath == "/CoverLetterTargetCompany")
      {
        this.isPageAllowed = true;
      } else {
        this.isPageAllowed = false;
      }
      next();
    },
  };
  </script>
  
 